import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { useAbility } from '@casl/vue';

import { AppAbility, Ability } from '../server/utils/ability';
import { RoleWithAbilities } from '../types/role';

export const useAppAbility = () => useAbility<AppAbility>();

/**
 * This helper function is used to return ability for a specific permission role.
 *
 * @param rules Abilities of a permission role
 * @param ignoreConditions If true, it will ignore conditions
 */
export const usePermissionRoleAbility = (rules: Array<Omit<Ability, 'id' | 'roleId'>>, ignoreConditions = false) => {
  const { can, build } = new AbilityBuilder<AppAbility>(createMongoAbility);

  rules.forEach(({ action, subject, fields, conditions }) => {
    const conditionsToAdd = ignoreConditions ? {} : conditions;
    can(action, subject, fields ?? undefined, conditionsToAdd);
  });

  return build();
};

/**
 * This helper function filters out roles with `DEFAULT` flag, and return ability.
 *
 * @param roles Roles with their abilities
 * @param ignoreConditions If true, it will ignore conditions
 */
export const useDefaultPermissionRoleAbility = (roles: Array<RoleWithAbilities>, ignoreConditions: boolean) => {
  const defaultRoles = roles.filter(role => role.role.flag === 'DEFAULT');
  const defaultRoleAbilities = defaultRoles.map(defaultRole => defaultRole.abilities).flat();
  return usePermissionRoleAbility(defaultRoleAbilities, ignoreConditions);
};
